import CrmEntryValueHits, {
    SerializedCrmEntryValueHits,
} from "@/models/crm-entry-value-hits";
import CrmEntryOrder, {
    SerializedCrmEntryOrder,
} from "@/models/crm-entry-order";
import CrmEntryComment, {
    SerializedCrmEntryComment,
} from "@/models/crm-entry-comment";
import { differenceInDays } from "date-fns";

export const CRM_ENTRY_STATUS = {
    MORE_THAN_65_DAYS: "more-than-65-days",
    WITHIN_36_TO_65_DAYS: "within-36-to-65-days",
    WITHIN_35_DAYS: "within-35-days",
    SNOOZED: "snoozed",
};

export interface SerializedCrmEntry {
    id: string;
    identifier: string;
    category: string;
    status: string;
    orders?: Array<SerializedCrmEntryOrder>;
    names?: Array<SerializedCrmEntryValueHits>;
    emails?: Array<SerializedCrmEntryValueHits>;
    user_ids?: Array<SerializedCrmEntryValueHits>;
    comments?: Array<SerializedCrmEntryComment>;
    phone_numbers?: Array<SerializedCrmEntryValueHits>;
    category_logo: string | null;
    created_at: string;
    updated_at: string;
}

export default class CrmEntry {
    private readonly _id: string;
    private readonly _identifier: string;
    private readonly _category: string;
    private readonly _status: string;
    private readonly _orders: Array<CrmEntryOrder>;
    private readonly _names: Array<CrmEntryValueHits>;
    private readonly _emails: Array<CrmEntryValueHits>;
    private readonly _userIds: Array<CrmEntryValueHits>;
    private readonly _comments: Array<CrmEntryComment>;
    private readonly _phoneNumbers: Array<CrmEntryValueHits>;
    private readonly _createdAt: Date;
    private readonly _categoryLogo: string;
    private readonly _updatedAt: Date;

    constructor(entry: SerializedCrmEntry) {
        this._id = entry.id;
        this._identifier = entry.identifier;
        this._category = entry.category;
        this._status = entry.status;
        this._orders = entry.orders
            ? entry.orders.map((order) => new CrmEntryOrder(order))
            : [];
        this._names = entry.names
            ? entry.names.map((name) => new CrmEntryValueHits(name))
            : [];
        this._emails = entry.emails
            ? entry.emails.map((email) => new CrmEntryValueHits(email))
            : [];
        this._userIds = entry.user_ids
            ? entry.user_ids.map((id) => new CrmEntryValueHits(id))
            : [];
        this._phoneNumbers = entry.phone_numbers
            ? entry.phone_numbers.map((number) => new CrmEntryValueHits(number))
            : [];
        this._comments = entry.comments
            ? entry.comments.map((comment) => new CrmEntryComment(comment))
            : [];
        this._createdAt = new Date(entry.created_at);
        this._categoryLogo = entry.category_logo
            ? entry.category_logo
            : "https://nyangapay.com/img/dstv.svg";
        this._updatedAt = new Date(entry.updated_at);
    }

    get phoneNumbers(): Array<CrmEntryValueHits> {
        return this._phoneNumbers;
    }

    get id(): string {
        return this._id;
    }

    get isDStv(): boolean {
        return this.category === "dstv";
    }

    get isAirtime(): boolean {
        return this.category === "airtime";
    }

    get isEneo(): boolean {
        return this.category === "eneo";
    }

    get categoryLogo(): string {
        return this._categoryLogo;
    }

    get lastOrderDate(): Date {
        if (this.orders.length === 0) {
            return new Date();
        }
        return this.orders[this.orders.length - 1].createdAt;
    }
    get identifier(): string {
        return this._identifier;
    }

    get category(): string {
        return this._category;
    }

    get canSnooze(): boolean {
        return (
            this.status != CRM_ENTRY_STATUS.WITHIN_35_DAYS &&
            this.status != CRM_ENTRY_STATUS.SNOOZED
        );
    }

    get lastComment(): CrmEntryComment | null {
        return this.comments.length > 0
            ? this.comments[this.comments.length - 1]
            : null;
    }

    get callStatus(): string {
        if (this.lastComment === null) {
            return "TO DO";
        }
        if (
            Math.abs(differenceInDays(new Date(), this.lastComment.timestamp)) <
            30
        ) {
            return "DONE";
        }
        return "TO DO";
    }

    get callStatusColor(): string {
        if (this.callStatus === "TO DO") {
            return "red darken-4";
        }
        return "green darken-3";
    }

    get orderCount(): number {
        return this.orders.length;
    }

    get commentCount(): number {
        return this.comments.length;
    }

    get lastCommentDate(): Date | null {
        if (this.lastComment === null) {
            return null;
        }
        return this.lastComment.timestamp;
    }

    get status(): string {
        return this._status;
    }

    get orders(): Array<CrmEntryOrder> {
        return this._orders;
    }

    get names(): Array<CrmEntryValueHits> {
        return this._names;
    }

    get emails(): Array<CrmEntryValueHits> {
        return this._emails;
    }

    get userIds(): Array<CrmEntryValueHits> {
        return this._userIds;
    }

    get comments(): Array<CrmEntryComment> {
        return this._comments;
    }

    get createdAt(): Date {
        return this._createdAt;
    }

    get updatedAt(): Date {
        return this._updatedAt;
    }
}
